import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import Select from "@material-ui/core/Select";
import MuiTable from "../Components/MuITable";
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MasterModel from "../Components/MasterModel";

import { CustomCard } from "../Components/CustomCard";
import CustomButton, { CircleAddBtnAnim } from "../Components/CustomButton";
import {
  updateUserMenuAccess,
  getAllRole,
  getAllUsers,
  postUser,
  updateUser,
  deleteUser,
  updateAccessibleMenus,
} from "../../services/associateService";
// import { getListShowroomWarehouse } from "../../services/showroomWarehouseService";

// for list

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Switch from "@material-ui/core/Switch";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Input, Box } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import TimelineIcon from "@material-ui/icons/Timeline";
import { ThemeProvider, FormControlLabel } from "@material-ui/core";
import { InputAdornment, IconButton, OutlinedInput } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PageTitle from "./HelperComponent/PageTitle";

import theme from "../../theme/theme";

import React from "react";
import {
  appFontWeightThin,
  appDefaultColor,
  appSecondColor,
  appDefaultFamily,
  appFontWeight,
  tblBodyHoverColor,
  appScrollBar,
} from "assets/jss/material-dashboard-pro-react";
import { useHistory } from "react-router-dom";
import CardLinkButton from "views/Components/CardLinkButton";
import { activeText } from "assets/jss/material-dashboard-pro-react";
import { Autocomplete } from "@material-ui/lab";
import Select from "react-select";
import { useStateValue } from "../../context/context";
import { actionTypes } from " ../../context/reducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import Check from "@material-ui/icons/Check";
import CustomCheckboxStyle from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
//////////For Accordion//////////////s//e//n/
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import clxs from "classnames";
import FileSaver from "file-saver";
import XLSX from "xlsx";
import { isConstructorDeclaration } from "typescript";

const useStyles1 = makeStyles(styles);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fff",
    color: appSecondColor,
    padding: "5px 10px",
    fontWeight: appFontWeight,
    fontFamily: appDefaultFamily,
    fontSize: "14px",
  },
  body: {
    color: appSecondColor,
    padding: "10px 10px",
    fontWeight: appFontWeightThin,
    fontFamily: appDefaultFamily,
    fontSize: "12.6px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {},
    "&:hover": {
      backgroundColor: tblBodyHoverColor,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  ...CustomCheckboxStyle,
  root: {
    switchBtn: {
      width: 180,
      height: "100%",
    },
  },

  catCards: {
    marginLeft: 5,
  },
  activeText: {
    ...activeText,
  },
  customSelect: {
    marginBottom: 15,
  },
  ddlError: {
    textAlign: "right",
    color: "#f44336",
    fontSize: "12.6px",
    marginRight: 15,
    marginTop: -15,
    fontWeight: 400,
  },
}));

const AddAssociatePage = () => {
  const classes1 = useStyles1();
  const history = useHistory();
  const classes = useStyles();
  const [classicModal, setClassicModal] = React.useState(false);
  const [userAccessModel, setUserAccessModel] = React.useState(false);
  const [globalState, dispatch] = useStateValue();
  const [refresh, setRefresh] = React.useState(false);
  const [allUsers, setAllUsers] = React.useState([]);
  const [allRoles, setAllRoles] = React.useState([]);
  const [allShowroomWarehouse, setAllShowroomWarehouse] = React.useState([]);
  const [showroomObject, setShowroomObject] = React.useState([]);
  const [showroomWarehouseId, setSelectShowroomWarehouse] = React.useState([]);
  const [selectedShowRoom, setSelectShowroom] = React.useState({});
  const [isChangePassword, setIsChangePasword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [loading, setLoading] = React.useState(false);
  const [accessMenu, setAccessMenu] = React.useState([]);
  const [menuObject, setMenuObject] = React.useState([]);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);

  const accessOption = [
    {
      label: "Web",
      value: 1,
    },
    {
      label: "Mobile",
      value: 2,
    },
    {
      label: "Both",
      value: 3,
    },
  ];
  const weekDays = [
    {
      label: "Monday",
      value: 1,
    },
    {
      label: "Tuesday",
      value: 2,
    },
    {
      label: "Wednesday",
      value: 3,
    },
    {
      label: "Thursday",
      value: 4,
    },
    {
      label: "Friday",
      value: 5,
    },
    {
      label: "Saturday",
      value: 6,
    },
    {
      label: "Sunday",
      value: 7,
    },
  ];
  // const weekDays = [
  //   {
  //     id: 1,
  //     name: "Monday",
  //     label: "Monday",
  //     hidden: false,
  //     required: true,
  //     data_type: "string",
  //     html_element: "TextField",
  //     error: false,
  //     xs: 12,
  //   },
  //   {
  //     id: 2,
  //     name: "Tuesday",
  //     label: "Tuesday",
  //     hidden: false,
  //     required: true,
  //     data_type: "string",
  //     html_element: "TextField",
  //     error: false,
  //     xs: 12,
  //   },
  //   {
  //     id: 3,
  //     name: "Wednesday",
  //     label: "Wednesday",
  //     hidden: false,
  //     required: true,
  //     data_type: "string",
  //     html_element: "TextField",
  //     error: false,
  //     xs: 12,
  //   },
  //   {
  //     id: 4,
  //     name: "Thursday",
  //     label: "Thursday",
  //     hidden: false,
  //     required: true,
  //     data_type: "string",
  //     html_element: "TextField",
  //     error: false,
  //     xs: 12,
  //   },
  //   {
  //     id: 5,
  //     name: "Friday",
  //     label: "Friday",
  //     hidden: false,
  //     required: true,
  //     data_type: "string",
  //     html_element: "TextField",
  //     error: false,
  //     xs: 12,
  //   },
  //   {
  //     id: 6,
  //     name: "Saturday",
  //     label: "Saturday",
  //     hidden: false,
  //     required: true,
  //     data_type: "string",
  //     html_element: "TextField",
  //     error: false,
  //     xs: 12,
  //   },
  //   {
  //     id: 7,
  //     name: "Sunday",
  //     label: "Sunday",
  //     hidden: false,
  //     required: true,
  //     data_type: "string",
  //     html_element: "TextField",
  //     error: false,
  //     xs: 12
  //   },
  // ]

  let allPermissions = [
    "1.Dashboard",

    "2.Transportation",
    "2.Transportation-2.1.Oil",
    "2.Transportation-2.2.Advance",
    "2.Transportation-2.3.loading",
    "2.Transportation-2.4.Salescumdispatch",
    "2.Transportation-2.5.Tracking",
    "2.Transportation-2.6.ExtraCharges",
    "2.Transportation-2.7.Expenses",

    "3.Maintenance",
    "3.Maintenance-3.1.Rejected Tyre",
    "3.Maintenance-3.2.Rejected Tyre Sales & Repair",
    "3.Maintenance-3.3.Purchase & Return Tyre",
    "3.Maintenance-3.4.TyreFitting",
    "3.Maintenance-3.5.Maintance",
    "3.Maintenance-3.6.Service Page",
    "3.Maintenance-3.7.Break Down",


    "4.Procurement",
    "4.Procurement-4.1.Direct Purchase",


    "5.Account",
    "5.Account-5.1.Receipt",
    "5.Account-5.2.Payment",
    "5.Account-5.3.Journal",
    "5.Account-5.4.Ledger",
    "5.Account-5.5.Cash Book",
    "5.Account-5.6.Receipt Sales Vehicle",
    "5.Account-5.7.Distance Covered Manual",

    "6.MIS Report",
    "6.MIS Report-6.1.Outstanding",
    "6.MIS Report-6.2.Outstanding Details",
    "6.MIS Report-6.3.Expiry Document",
    "6.MIS Report-6.4.Total Distance Covered",
    "6.MIS Report-6.5.Daily Report Summary",
    "6.MIS Report-6.6.Daily Truck Report",
    "6.MIS Report-6.7.Daily Maintance Report",
    "6.MIS Report-6.8.InTransit Report",
    
    "7.Master",
    "7.Master-7.1.Account",
    "7.Master-7.2.Vehicle",
    "7.Master-7.3.Tyre",
    "7.Master-7.4.Users",
    "7.Master-7.5.Role",   
    "7.Master-7.6.Customer",
    "7.Master-7.7.Vendor",
    "7.Master-7.8.Employee",
    "7.Master-7.9.Material",
    "7.Master-7.10.Material Type",
    "7.Master-7.11.Item",
    "7.Master-7.12.Setting",
   
  ];
  let topLevelMenus = [
    "1.Dashboard",
    "2.Transportation",
    "3.Maintenance",
    "4.Procurement",
    "5.Account",
    "6.MIS Reports",
    "7.Master",
  ];
  // const [selectedUser, setSelectedUser] = React.useState({
  //   name: "",
  //   role: "",
  //   role_id: "",
  //   email: "",
  //   mobile: "",
  // });
  const [error, setError] = React.useState({
    txt_name: false,
    txt_password: false,
    txt_email: false,
    txt_mobile: false,
    ddl_role_id: false,
  });

  const [addUser, setAddUser] = React.useState({
    edit: false,
    user_id: "",
    txt_name: "",
    txt_mobile: "",
    txt_email: "",
    txt_password: "",
    ddl_role_id: "",
    ddl_role_label: "Select Role",

    switch_active_status: false,
  });
  const [state, setState] = React.useState({
    edit: false,
    showAccess: false,
  });
  const [accessTime, setAccessTime] = React.useState([]);
  //animation
  const [anim, setAnim] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    setState({ ...state });
    // get All Role
    getAllRole(
      (r) => {
        setAllRoles(r);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
    // get All Users
    getAllUsers(
      (r) => {
        setAllUsers(r);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );

    let arr2 = [];
    allPermissions.map((s, i) => {
      arr2.push({
        id: s,
        name: s,
        label: s,
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 6,
      });

      setMenuObject(arr2);
    });
  }, [refresh]);

  const onSetActive = (e) => {
    setAddUser((prv) => ({ ...prv, [e.target.name]: e.target.checked }));
  };

  // const onSelectShowroom = (e, id) => {
  //   const currentIndex = showroomWarehouseId.indexOf(id);
  //   const newshowroomWarehouseId = [...showroomWarehouseId];

  //   if (currentIndex === -1) {
  //     newshowroomWarehouseId.push(id);
  //   } else {
  //     newshowroomWarehouseId.splice(currentIndex, 1);
  //   }
  //   setSelectShowroomWarehouse(newshowroomWarehouseId);
  //   // setSelectShowroom((prv) => ({ ...prv, [e.target.name]: e.target.checked }));
  // };

  const onSelectRole = (v) => {
    if (v !== null) {
      setAddUser({
        ...addUser,
        ddl_role_id: v.value,
        ddl_role_label: v.label,
      });
    }
  };
  const onSelectAccess = (v) => {
    if (v !== null) {
      setAnim(false);
      setAddUser({
        ...addUser,
        ddl_weekDays_id: v.value,
        ddl_weekDays_label: v.label,
      });
    }
  };
  const headerData = [
    {
      id: "id",
      label: "#",
    },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "role_name",
      label: "Role",
    },
    {
      id: "mobile",
      label: "Mobile",
    },
    {
      id: "email",
      label: "User Name",
    },
    {
      id: "status",
      label: "Status",
      statusBtn: (v) => (v === "Y" ? true : false),
    },
    {
      id: "action",
      label: "Action",
      align: "right",
      viewMenu: (v) => v,
    },
  ];

  const onChange = (e) => {
    const { value, name } = e.target;

    if (name === "txt_email") {
      setAddUser({ ...addUser, [name]: value.replace(/ /g, "") });
    } else {
      setAddUser({ ...addUser, [name]: value });
    }
  };

  // onSubmit called
  const onSubmitModel = (e) => {
    e.preventDefault();
    if (
      !addUser.txt_name ||
      !addUser.txt_email ||
      !addUser.txt_mobile ||
      !addUser.ddl_role_id ||
      !accessTime
    ) {
      setError({
        txt_name: !addUser.txt_name,
        txt_email: !addUser.txt_email,
        txt_mobile: !addUser.txt_mobile,
        ddl_role_id: !addUser.ddl_role_id,
      });
    }
    if (addUser.edit) {
      updateUser(
        addUser,
        (r) => {
          onCloseModel();
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: {
              msg: "User Updated Successfully",
              msgType: "success",
            },
          });
          setRefresh(!refresh);
        },
        (err) => {
          dispatch({
            type: actionTypes.SET_OPEN_MSG,
            payload: { msg: err, msgType: "error" },
          });
        }
      );
    } else {
      if (!addUser.txt_password) {
        setError({ txt_password: !addUser.txt_password });
      } else {
        postUser(
          addUser,
          globalState?.user?.serial_id,
          (r) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: {
                msg: "User added Successfully",
                msgType: "success",
              },
            });
            onCloseModel();
            setRefresh(!refresh);
          },
          (err) => {
            dispatch({
              type: actionTypes.SET_OPEN_MSG,
              payload: { msg: err, msgType: "error" },
            });
            setButtonDisabled(true);
          }
        );
      }
    }
  };

  // on User Update
  const onUserEdit = (row) => {
    console.log(row, "san2607/edit");
    setClassicModal(true);
    setIsChangePasword(false);
    setAccessTime(row?.weekDays);
    setAddUser({
      ...addUser,
      edit: true,
      user_id: row.user_id,
      txt_name: row.name,
      txt_mobile: row.mobile,
      txt_email: row.email,
      ddl_role_id: row.role_id,
      ddl_role_label: row.role_name,
      txt_password: row.password,
      switch_active_status: row.status === "Y" ? true : false,
    });
  };

  // on Close Model
  const onCloseModel = () => {
    setShowPassword(false);
    setClassicModal(false);
    setSelectShowroomWarehouse([]);
    setSelectShowroom({});
    setAccessTime([]);
    setError({});
    setAddUser({
      ...addUser,
      edit: false,
      user_id: "",
      txt_name: "",
      txt_mobile: "",
      txt_email: "",
      txt_password: "",
      dd_role_id: 0,
      ddl_role_label: "Select Role",
      switch_active_status: false,
    });
  };

  const onDeleteUser = (row, next) => {
    deleteUser(
      row.user_id,
      (r) => {
        next();
        setRefresh(!refresh);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
  };
console.log(globalState,"sankhaglobal1")
const onClickUserAccess = (row) => {
  setUserAccessModel(true);
  setAddUser({
    ...addUser,
    edit: true,
    user_id: row.user_id,
    txt_name: row.name,
    txt_mobile: row.mobile,
    txt_email: row.email,
    // txt_password: row.password,
    ddl_role_id: row.role_id,
    ddl_role_label: row.role_name,
  });
  setAccessMenu(row.accessible_menus);
};
  

  const onCloseUserAccessModel = () => {
    setUserAccessModel(false);
  };

  const onCheckingMenuItem = (item, e) => {
    //console.log("itema", item);
    let newAccessMenu = [...accessMenu];
    //console.log("new access menu", newAccessMenu);

    // checking for top level menu

    if (topLevelMenus.includes(item)) {
      if (!newAccessMenu.includes(item)) {
        let fl = allPermissions.filter((o) => o.indexOf(item) === 0);

        fl.map((f) => {
          if (newAccessMenu.includes(f))
            newAccessMenu = newAccessMenu.filter((o) => o !== f);
          newAccessMenu.push(f);
        });
      } else {
        newAccessMenu = newAccessMenu.filter((o) => o.indexOf(item) !== 0);
      }
    }

    // checking for submenu
    else if (item.indexOf("-") > -1) {
      let ind_levels = item.split("-");

      if (newAccessMenu.includes(ind_levels[0])) {
        const currentIndex = newAccessMenu.indexOf(ind_levels[0]);
        newAccessMenu.splice(currentIndex, 1);
      }

      if (newAccessMenu.includes(item)) {
        const currentIndex = newAccessMenu.indexOf(item);
        newAccessMenu.splice(currentIndex, 1);
      } else {
        newAccessMenu.push(item);
        let allSubMenus = allPermissions.filter(
          (o) => o.indexOf(ind_levels[0]) === 0 && o.indexOf("-") > -1
        );
        if (allSubMenus.every((val) => newAccessMenu.includes(val))) {
          newAccessMenu.push(ind_levels[0]);
        }
      }
    } else {
      const currentIndex = newAccessMenu.indexOf(item);

      if (currentIndex === -1) {
        newAccessMenu.push(item);
      } else {
        newAccessMenu.splice(currentIndex, 1);
      }
    }

    setAccessMenu(newAccessMenu);
  };

  // const onCheckingMenuItem = (item, e) => {
    
  //   if (accessMenu) {
  //     let newAccessMenu = [...accessMenu];
  
  //     if (topLevelMenus.includes(item)) {
       
  //       if (newAccessMenu.includes(item)) {
         
  //         let subMenus = allPermissions.filter((perm) => perm.startsWith(item + "-"));
  //         newAccessMenu = newAccessMenu.filter((perm) => !subMenus.includes(perm));
  //       } else {
         
  //         let subMenus = allPermissions.filter((perm) => perm.startsWith(item + "-"));
  //         newAccessMenu = newAccessMenu.concat([item, ...subMenus]);
  //       }
  //     } else if (item && item.indexOf("-") > -1) {
       
  //       let ind_levels = item.split("-");
  //       let topLevel = ind_levels[0];
  
  //       if (newAccessMenu.includes(item)) {
         
  //         const currentIndex = newAccessMenu.indexOf(item);
  //         newAccessMenu.splice(currentIndex, 1);
  //       } else {
        
  //         newAccessMenu.push(item);
  //       }
  
       
  //       let allSubMenus = allPermissions.filter((perm) => perm.startsWith(topLevel + "-"));
  //       if (allSubMenus.every((perm) => newAccessMenu.includes(perm))) {
  //         newAccessMenu.push(topLevel);
  //       } else {
          
  //         const topLevelIndex = newAccessMenu.indexOf(topLevel);
  //         if (topLevelIndex !== -1) {
  //           newAccessMenu.splice(topLevelIndex, 1);
  //         }
  //       }
  //     } else {
      
  //       const currentIndex = newAccessMenu.indexOf(item);
  //       if (currentIndex === -1) {
  //         newAccessMenu.push(item);
  //       } else {
  //         newAccessMenu.splice(currentIndex, 1);
  //       }
  //     }
  
  //     setAccessMenu(newAccessMenu);
  //   }
  // };
  
  
  
  


  const showAccessMenu = (e) => {
    e.preventDefault();
    console.log("Access menu", accessMenu);

    updateUserMenuAccess(
      accessMenu.sort(),
      addUser,
      (r) => {
        onCloseModel();
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: {
            msg: "User Updated Successfully",
            msgType: "success",
          },
        });
        setRefresh(!refresh);
      },
      (err) => {
        dispatch({
          type: actionTypes.SET_OPEN_MSG,
          payload: { msg: err, msgType: "error" },
        });
      }
    );
    setUserAccessModel(false);
  };

  const onAddAccess = () => {
    setState((prv) => ({ ...prv, showAccess: true }));
  };

  const onClickAdd = (e) => {
    setAnim(true);
    setAccessTime([
      ...accessTime,
      {
        ddl_weekDays_id: addUser.ddl_weekDays_id,
        ddl_weekDays_label: addUser.ddl_weekDays_label,
        txt_End_time: addUser.txt_End_time,
        txt_Start_time: addUser.txt_Start_time,
        app_status: addUser.app_status,
        web_status: addUser.web_status,
      },
    ]);
  };
  console.log(accessTime, "sank2406");

  const onDelete = (row, id) => {
    console.log(row, "sank2706");
    console.log(id, "sank2706");

    let deleteref = accessTime;
    deleteref.splice(id, 1);

    dispatch({
      type: actionTypes.SET_OPEN_MSG,
      payload: { msg: "Deleted!!!!", msgType: "error" },
    });
  };

  // const onEdit = (row) => {

  //   setAccessTime({
  //     ...accessTime,
  //     edit: true,
  //     ddl_weekDays_id:addUser.ddl_weekDays_id,
  //     ddl_weekDays_label:addUser.ddl_weekDays_label,
  //     txt_End_time:addUser.txt_End_time,
  //     txt_Start_time:addUser.txt_Start_time,
  //     app_status:addUser.app_status,
  //     web_status:addUser.web_status,
  //   });
  // };

  const formData = {
    formName: "Add a User",
    fields: [
      {
        name: "txt_name",
        label: "Name",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "TextField",
        error: false,
        xs: 6,
      },
      {
        name: "ddl_role",
        label: "Role",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "select",
        error: false,
        options: allRoles,
        xs: 6,
      },
      {
        name: "txt_mobile",
        label: "Mobile",
        hidden: false,
        required: true,
        maxLength: 10,
        data_type: "tel",
        html_element: "TextField",
        error: false,
        xs: 6,
      },
      {
        name: "txt_email",
        label: "User Name",
        hidden: false,
        required: true,
        type: "email",
        data_type: "email",
        html_element: "TextField",
        error: false,
        xs: 6,
      },

      {
        name: "txt_password",
        label: "Password",
        hidden: false,
        required: true,
        data_type: "password",
        html_element: "password",
        error: false,
        xs: 6,
      },

      {
        name: "switch_active_status",
        label: "Active Status",
        defaultValue: false,
        required: false,
        data_type: "string",
        html_element: "switch",
        error: false,
        xs: 4,
      },
    ],
  };

  const formData2 = {
    formName: "Grant Permissions",
    fields: [
      {
        id: "1.Dashboard",
        name: "Dashboard",
        label: "Dashboard",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        fontWeight: "bold",
        xs: 12,
        md: 12,
        lg: 12,
      },
      {
        id: "2.Transportation",
        name: "Transportation",
        label: "Transportation",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 12,
      },

      {
        id:  "2.Transportation-2.1.Oil",
        name: "Transportation-Oil",
        label: "Oil",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
        md: 3,
        lg: 3,
      },
      {
        id:"2.Transportation-2.2.Advance",
        name: "Transportation-Advance",
        label: "Advance",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "2.Transportation-2.3.loading",
        name: "Transportation-loading",
        label: "loading",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "2.Transportation-2.4.Salescumdispatch",
        name: "Transportation-Salescumdispatch",
        label: "Sales-cum-dispatch",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id:"2.Transportation-2.5.Tracking",
        name: "Transportation-Tracking",
        label: "Tracking",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id:"2.Transportation-2.6.ExtraCharges",
        name: "Transportation-ExtraCharges",
        label: "ExtraCharges",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "2.Transportation-2.7.Expenses",
        name: "Transportation-Expenses",
        label: "Expenses",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },

      {
        id:  "3.Maintenance",
        name: "Maintenance",
        label: " Maintenance",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 12,
      },
      {
        id:  "3.Maintenance-3.1.Rejected Tyre",
        name: "Maintenance-Rejected Tyre",
        label: "Rejected Tyre",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "3.Maintenance-3.2.Rejected Tyre Sales & Repair",
        name: "Maintenance-Rejected Tyre Sales & Repair",
        label: "Rejected Tyre Sales & Repair",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id:"3.Maintenance-3.3.Purchase & Return Tyre",
        name: "Maintenance-Purchase & Return Tyre",
        label: "Purchase & Return Tyre",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id:  "3.Maintenance-3.4.TyreFitting",
        name: "Maintenance-TyreFitting",
        label: "TyreFitting",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "3.Maintenance-3.5.Maintance",
        name: "Maintenance-Maintance",
        label: "Maintance",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "3.Maintenance-3.6.Service Page",
        name: "Maintenance-Service Page",
        label: "Service Page",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "3.Maintenance-3.7.Break Down",
        name: "Maintenance-Break Down",
        label: "Break Down",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "4.Procurement",
        name: "Procurement",
        label: " Procurement",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 12,
      },
      {
        id: "4.Procurement-4.1.Direct Purchase",
        name: "Procurement-Direct Purchase",
        label: "Direct Purchase",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 12,
      },
      {
        id: "5.Account",
        name: "Account",
        label: " Account",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 12,
      },
      {
        id:  "5.Account-5.1.Receipt",
        name: "Account-Receipt",
        label: " Receipt",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "5.Account-5.2.Payment",
        name: "Account-Payment",
        label: " Payment",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "5.Account-5.3.Journal",
        name: "Account-Journal",
        label: " Journal",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "5.Account-5.4.Ledger",
        name: "Account-Ledger",
        label: " Ledger",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "5.Account-5.5.Cash Book",
        name: "Account-Cash Book",
        label: "Cash Book",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "5.Account-5.6.Receipt Sales Vehicle",
        name: "Account-Receipt Sales Vehicle",
        label: "Receipt Sales Vehicle",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "5.Account-5.7.Distance Covered Manual",
        name: "Account-Distance Covered Manual",
        label: "Distance Covered Manual",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "6.MIS Report",
        name: "MIS Report",
        label: "MIS Reports",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 12,
      },
      {
        id: "6.MIS Report-6.1.Outstanding",
        name: "MIS Report-Outstanding",
        label: "Outstanding",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "6.MIS Report-6.2.Outstanding Details",
        name: "MIS Report-Outstanding Details",
        label: "Outstanding Details",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "6.MIS Report-6.3.Expiry Document",
        name: "MIS Report-Expiry Document",
        label: "Expiry Document",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "6.MIS Report-6.4.Total Distance Covered",
        name: "MIS Report-Total Distance Covered",
        label: "Total Distance Covered",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "6.MIS Report-6.5.Daily Report Summary",
        name: "MIS Report-Daily Report Summary",
        label: "Daily Report Summary",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "6.MIS Report-6.6.Daily Truck Report",
        name: "MIS Report-Daily Truck Report",
        label: "Daily Truck Report",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "6.MIS Report-6.7.Daily Maintance Report",
        name: "MIS Report-Daily Maintance Report",
        label: "Daily Maintance Report",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "6.MIS Report-6.8.InTransit Report",
        name: "MIS Report-InTransit Report",
        label: "InTransit Report",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },

      {
        id:"7.Master",
        name: "Master",
        label: "Master",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 12,
      },

      {
        id: "7.Master-7.1.Account",
        name: "Master-Account",
        label: "Account ",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "7.Master-7.2.Vehicle",
        name: "Master-Vehicle",
        label: "Vehicle ",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id:  "7.Master-7.3.Tyre",
        name: "Master-Tyre",
        label: "Tyre Model",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "7.Master-7.4.Users",
        name: "Master-Users",
        label: "Users",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "7.Master-7.5.Role",
        name: "Master-Role",
        label: "Role",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
     

  
      {
        id: "7.Master-7.6.Customer",
        name: "Master-Customer",
        label: "Vehicle Type",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
     
      {
        id: "7.Master-7.7.Vendor",
        name: "Master-Vendor",
        label: "Vendor",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "7.Master-7.8.Employee",
        name: "Master-Employee",
        label: "Employee",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id: "7.Master-7.9.Material",
        name: "Master-Material",
        label: "Material",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
      {
        id:  "7.Master-7.10.Material Type",
        name: "Master-Material Type",
        label: "Material Type",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },


      {
        id: "7.Master-7.11.Item",
        name: "Master-Item",
        label: "Item",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
   
      {
        id: "7.Master-7.12.Setting",
        name: "Master-Setting",
        label: "Setting",
        hidden: false,
        required: true,
        data_type: "string",
        html_element: "checkBox",
        error: false,
        xs: 3,
      },
    ],
  };

  // export to excel

  const onhandleExportToExcel = () => {
    const UsersTable = allUsers.map((acc) => {
      return {
        ID: acc.id,
        Name: acc.name,
        Role: acc.role_name,
        Mobile: acc.mobile,
        Email: acc.email,
        Status: acc.status,
        Action: "",
      };
    });
    const fileName = "Users";
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(UsersTable);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <ThemeProvider theme={theme}>
      <PageTitle
        title="Master > Users"
        btnToolTip="Add New User"
        onClickAddBtn={() => {
          setClassicModal(true);
          setIsChangePasword(true);
        }}
      />
      <>
        <GridContainer className={classes.root}>
          <MasterModel
            classicModal={classicModal}
            onCloseModel={onCloseModel}
            width={550}
            height="auto"
            okBtnText="Submit"
            modelName={addUser.edit ? "Edit User" : "Add a User"}
            onClickOk={onSubmitModel}
            disabled={buttonDisabled}
          >
            <div style={{ padding: "20px 10px", width: "100%" }}>
              <GridContainer>
                {formData.fields.map((item, key) => {
                  return (
                    <>
                      <GridItem
                        xs={item.xs}
                        md={item.md}
                        lg={item.lg}
                        key={key}
                      >
                        {item.html_element === "select" && (
                          <>
                            <InputLabel id="label">{item.label}</InputLabel>
                            <Select
                              options={item.options}
                              placeholder="Select Module"
                              formatGroupLabel={(d) => d.label}
                              className={classes.customSelect}
                              onChange={onSelectRole}
                              value={{
                                value: addUser.ddl_role_id,
                                label: addUser.ddl_role_label,
                              }}
                            />
                            {error.ddl_role_id && (
                              <div className={classes.ddlError}>
                                {item.label} is Required
                              </div>
                            )}
                          </>
                        )}
                        {item.html_element === "select_two" && (
                          <>
                            <InputLabel id="label">{item.label}</InputLabel>
                            <Select
                              options={item.options}
                              placeholder="Select Module"
                              formatGroupLabel={(d) => d.label}
                              className={classes.customSelect}
                              onChange={onSelectAccess}
                              value={{
                                value: addUser.ddl_weekDays_id,
                                label: addUser.ddl_weekDays_label,
                              }}
                            />
                            {error.ddl_role_id && (
                              <div className={classes.ddlError}>
                                {item.label} is Required
                              </div>
                            )}
                          </>
                        )}
                        {item.html_element === "TextField" && (
                          <>
                            <InputLabel required={item.required} id="label">
                              {item.label}
                            </InputLabel>
                            <TextField
                              required={item.required}
                              size="small"
                              placeholder={item.label}
                              name={item.name}
                              type={item.data_type}
                              inputProps={{
                                maxLength: item.maxLength,
                                max: item.maxLength,
                              }}
                              onChange={onChange}
                              error={error[item.name]}
                              FormHelperTextProps={{
                                style: { textAlign: "right" },
                              }}
                              helperText={
                                error[item.name]
                                  ? item.label + " is required"
                                  : ""
                              }
                              id="outlined-basic"
                              fullWidth={true}
                              value={addUser[item.name]}
                              variant="outlined"
                            />
                          </>
                        )}

                        {item.html_element === "password" &&
                          // addUser.edit === false &&
                          (isChangePassword ? (
                            <>
                              <InputLabel
                                style={{ marginTop: 15 }}
                                required={!addUser.edit && item.required}
                                id="label"
                              >
                                {item.label}
                              </InputLabel>
                              <TextField
                                required={!addUser.edit && item.required}
                                size="small"
                                placeholder={item.label}
                                name={item.name}
                                error={error[item.name]}
                                FormHelperTextProps={{
                                  style: { textAlign: "right" },
                                }}
                                helperText={
                                  error[item.name]
                                    ? item.label + " is required"
                                    : ""
                                }
                                type={showPassword ? "text" : "password"}
                                onChange={onChange}
                                InputProps={{
                                  // <-- This is where the toggle button is added.
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                      >
                                        {showPassword ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                id="outlined-basic"
                                fullWidth={true}
                                value={addUser[item.name]}
                                variant="outlined"
                              />
                            </>
                          ) : (
                            <>
                              <InputLabel style={{ marginTop: 15 }} id="label">
                                Change {item.label}
                              </InputLabel>
                              <TextField
                                size="small"
                                placeholder={item.label}
                                type={"password"}
                                InputProps={{
                                  // <-- This is where the toggle button is added.
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Box
                                        onClick={() => setIsChangePasword(true)}
                                        color={appDefaultColor}
                                        style={{ cursor: "pointer" }}
                                      >
                                        Change Password
                                      </Box>
                                    </InputAdornment>
                                  ),
                                }}
                                id="outlined-basic"
                                fullWidth={true}
                                value={"password"}
                                variant="outlined"
                              />
                            </>
                          ))}

                        {item.html_element === "Label" && (
                          <>
                            <InputLabel
                              // style={{ marginTop: 15 }}
                              required={item.required}
                              id="label"
                            >
                              {item.label}
                            </InputLabel>
                          </>
                        )}

                        {item.html_element === "checkBox" && (
                          <>
                            <div className={classes.checkboxAndRadio}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    className={classes.checkbox}
                                    // onClick={(e) =>
                                    //   onSelectShowroom(e, item.id)
                                    // }
                                    // checked={
                                    //   showroomWarehouseId.indexOf(item.id) !==
                                    //   -1
                                    // }
                                    // checked={
                                    //   selectedShowRoom[
                                    //     `${item.name}_${item.id}`
                                    //   ]
                                    // }
                                    // name={`${item.name}_${item.id}`}
                                    // value={
                                    //   selectedShowRoom[
                                    //     `${item.name}_${item.id}`
                                    //   ]
                                    //     ? selectedShowRoom[
                                    //         `${item.name}_${item.id}`
                                    //       ]
                                    //     : false
                                    // }
                                    color="primary"
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  root: classes.labelRoot,
                                }}
                                label={item.label}
                              />
                            </div>
                          </>
                        )}

                        {item.html_element === "TextArea" && (
                          <>
                            <InputLabel id="label">{item.label}</InputLabel>

                            <TextField
                              placeholder={item.label}
                              name={item.name}
                              onChange={onChange}
                              multiline
                              rows={5}
                              id="outlined-basic"
                              fullWidth={true}
                              value={addUser[item.name]}
                              variant="outlined"
                            />
                          </>
                        )}
                        {item.html_element === "switch" && (
                          <div
                            style={{
                              marginTop: 35,
                              marginLeft: 50,
                              width: "100%",
                            }}
                          >
                            <span className={classes.activeText}>
                              {item.label}
                            </span>
                            <Switch
                              onClick={onSetActive}
                              checked={addUser[item.name]}
                              name={item.name}
                              fullWidth={true}
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                              color="primary"
                            />
                          </div>
                        )}

                        {item.html_element === "button" && (
                          <>
                            {/* <span className={classes.activeText}>
                             
                            </span> */}
                            <CircleAddBtnAnim
                              title="Add"
                              // style={{
                              //   border:"solid",borderColor:"black" ,
                              //   marginTop:"20px",
                              //   width:"30px",
                              //   height:"30px",
                              //   float:"right"
                              // }}
                              // title={btnToolTip}
                              onClick={onClickAdd}
                              anim={anim}
                            />
                          </>
                        )}
                      </GridItem>
                    </>
                  );
                })}
              </GridContainer>
            </div>
          </MasterModel>

          <MasterModel
            classicModal={userAccessModel}
            onCloseModel={onCloseUserAccessModel}
            width={800}
            height="auto"
            okBtnText="Grant"
            modelName="Grant User Access"
            onClickOk={(e) => showAccessMenu(e)}
          >
            <div style={{ padding: "20px 10px", width: "100%" }}>
              <GridContainer>
                {formData2.fields.map((item, key) => {
                  return (
                    <>
                      {/* <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>Accordion 1</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget.
                          </Typography>
                        </AccordionDetails>
                      </Accordion> */}
                      <GridItem
                        xs={item.xs}
                        md={item.md}
                        lg={item.lg}
                        // fontWeight={item.fontWeight}
                        key={key}
                      >
                        {item.html_element === "checkBox" && (
                          <>
                            <CustomCard style={{ float: 'left', display: 'flex', }}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    className={classes.checkbox}
                                    onClick={(e) =>
                                      onCheckingMenuItem(item.id, e)
                                    }
                                    checked={accessMenu.indexOf(item.id) !== -1}
                                    color="primary"
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                  root: classes.labelRoot,
                                }}
                                label={item.label}

                              />
                              {/* <>
                              <b>{item.label}</b>
                              </> */}
                            </CustomCard>
                          </>
                        )}
                      </GridItem>
                    </>
                  );
                })}
              </GridContainer>
            </div>
          </MasterModel>

          <GridItem xs="12">
            {loading ? (
              <Box mt={10} width="100%" textAlign="center">
                <CircularProgress />
              </Box>
            ) : (
              <Card className={classes1.headerCard}>
                <CardHeader
                  className={classes1.TbheaderCdhd}
                  style={{ height: 60 }}
                >
                  <GridContainer
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <GridItem>
                      <h4 className={classes1.headerCdTitle}>Users</h4>
                    </GridItem>
                    <GridItem style={{ cursor: "pointer" }}>
                      <IconButton
                        variant="text"
                        onClick={() => onhandleExportToExcel(allUsers)}
                      >
                        <Tooltip title="Export to Excel">
                          <img
                            src={require("../../assets/img/excel.png").default}
                          />
                        </Tooltip>
                      </IconButton>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody
                  style={{ height: "auto", maxHeight: 480, padding: 10 }}
                  className={clxs(classes.customScroolBar)}
                >
                  <MuiTable
                    onClickEdit={onUserEdit}
                    onClickDelete={onDeleteUser}
                    onClickUserAccess={onClickUserAccess}
                    columns={headerData}
                    rows={allUsers}
                  />
                </CardBody>
              </Card>
            )}
          </GridItem>

          {/* Currently not in use and the calling function are commented in bottom  */}
        </GridContainer>
      </>
    </ThemeProvider>
  );
};

export default AddAssociatePage;
